import ImagesData from "../../../../../data/assets/images/images";
import Styles from "./styled";
import usePlayerStats from "./hook";

const { coin, diamond, pointsFlash } = ImagesData;

const {
    Wrapper,
    Header,
    Col40,
    Col60,
    Row,
    Icon,
    NavText,
    HeroImage,
    PointsGain,
    DoubleNavText,
    DoubleNavTextContainer,
} = Styles;

const PlayerStats = () => {
    const {
        pointsToDisplay,
        PlayerTokens,
        coinRewardFlashActive,
        coinsToGive,
        currentLevelName,
        reward,
        coinRewardTrigger,
        levelIcon,
        PlayerNickName,
        hero,
    } = usePlayerStats();

    // // @ts-ignore
    // const hero = avatarTable[heroName];

    return (
        <Wrapper>
            <Col40>
                <HeroImage top={hero.top} right={hero.right} width={hero.width}>
                    <img src={hero.avatar} alt="avatar" />
                </HeroImage>
            </Col40>
            <Col60>
                <Row>
                    <Header>{PlayerNickName}</Header>
                </Row>
                <Row>
                    <Icon>
                        <img src={levelIcon} alt="level icon" />
                    </Icon>
                    {currentLevelName.includes(" ") ? (
                        <DoubleNavTextContainer>
                            <DoubleNavText>
                                {currentLevelName.split(" ")[0]}
                            </DoubleNavText>
                            <DoubleNavText>
                                {currentLevelName.split(" ")[1]}
                            </DoubleNavText>
                        </DoubleNavTextContainer>
                    ) : (
                        <NavText>{currentLevelName}</NavText>
                    )}
                    <Icon>
                        <PointsGain
                            src={pointsFlash + "?x=" + coinRewardTrigger}
                            active={coinRewardFlashActive}
                        />
                        <img src={coin} alt="coin" />
                    </Icon>
                    <NavText>x{coinsToGive}</NavText>
                    {/* {reward && reward.type === "coin" ? (
                        <NavText>
                            x
                            {coinRewardFlashActive === true
                                ? PlayerTokens
                                : parseInt(PlayerTokens) - 1}
                        </NavText>
                    ) : (
                        <NavText>x{PlayerTokens}</NavText>
                    )} */}
                    <Icon>
                        <img src={diamond} alt="diamond" />
                    </Icon>
                    <NavText>x{pointsToDisplay}</NavText>
                </Row>
            </Col60>
        </Wrapper>
    );
};

export default PlayerStats;
