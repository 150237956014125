import ImagesData from "../../../../data/assets/images/images";

const { kioskIcon, kioskIconGreen } = ImagesData;

const kiosksLookupTable = {
    "1": kioskIcon,
    "2": kioskIcon,
    "3": kioskIcon,
    "4": kioskIcon, ///labirynt
    "5": kioskIcon,
    "6": kioskIcon,
    "7": kioskIcon,
    "8": kioskIcon,
    "10": kioskIcon,
    "12": kioskIcon,
    "13": kioskIconGreen,
    "14": kioskIconGreen,
    "15": kioskIconGreen,
    "16": kioskIconGreen,
    "17": kioskIcon,
    "19": kioskIcon,
    "20": null,
};

export { kiosksLookupTable };
