import { useEffect, useState } from "react";
import ImagesData from "../../../../../data/assets/images/images";
import Styles from "./styled";

const {
    Container,
    BonusWrapper,
    BonusIcon,
    BonusQuantity,
    TimeWrapper,
    Time,
    TimeIcon,
    Multiplay,
} = Styles;

const { diamond, clock } = ImagesData;

const BonusReward = (props: any) => {
    const [time, setTime] = useState("");
    useEffect(() => {
        if (props.reward.whenapplied === "3" && props.reward.noofsec) {
            const minutes = Math.floor(parseInt(props.reward.noofsec) / 60);
            const seconds = parseInt(props.reward.noofsec) - minutes * 60;
            const secondsString = (seconds.toFixed(2) + "").split(".")[1];
            setTime(minutes + ":" + secondsString);
        }
    }, [props.reward]);
    return (
        <Container>
            <BonusWrapper>
                <BonusIcon src={diamond} />
                {/* <Multiplay>{props.reward.formula}</Multiplay> */}
                <BonusQuantity>{props.reward.formula}</BonusQuantity>
            </BonusWrapper>
            {time !== "" && (
                <TimeWrapper>
                    <TimeIcon src={clock} />
                    <Time>{time}</Time>
                </TimeWrapper>
            )}
        </Container>
    );
};

export default BonusReward;
