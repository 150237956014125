import React from "react";
import ScreenFrame from "../../../templates/ScreenFrame";
import ScreenBackground from "../../../templates/ScreenBackground";
import VideoPlayer from "../../../templates/VideoPlayer";
import heroLoadVideo from '../../../../data/assets/videos/hero-load.mp4'
import loadHeroBg from '../../../../data/assets/images/load-hero-bg.png'
import "./styles.css"

export default function HeroLoad (){

    return ( 
        <ScreenFrame isVertical>
            <img className='hero-load__bg' src={loadHeroBg}/>
            <VideoPlayer source={heroLoadVideo} autoPlay loop={false} muted controls={false}/>
        </ScreenFrame>
    )
}