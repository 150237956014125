import React from "react";
import GateEnter from "../../Gate/Enter";
import StartScanHand from "../../StartScanHand";

const Screen09 = () => {
    return <StartScanHand />;
    // return <GateEnter />;
};

export default Screen09;
