import ProgressBar from "./ProgressBar";
import ScreenFrame from "../../../templates/ScreenFrame";
import { heroIcons, heroBodies } from "../data";
import ImagesData from "../../../../data/assets/images/images";
import parkEnteranceDashboardBackground from "../../../../data/assets/images/parkEnteranceDashboard/ekran4_tlo.png"
import useParkEnteranceDashboard from "./hook";
import "./styles.css"

const { pointsFlash } = ImagesData

const ParkEntranceDashboard = () => {
    const {heroName,showRankingFlash,tokens,points,speed,climbing,jumping,agality,flair,placeInRanking,apiData} = useParkEnteranceDashboard()
    return (
        <>
            <ScreenFrame isVertical>
                <img className='park-enterance-dashboard__background' src={parkEnteranceDashboardBackground}/>
                <div className='park-enterance-dashboard__hero-title-container'>
                    <img className='park-enterance-dashboard__hero-icon' src={heroIcons[heroName] }/>
                    <span className='park-enterance-dashboard__hero-title'>{apiData?.PlayerNickName}</span>
                </div>
                <ProgressBar />
                <span className="park-enterance-dashboard__stat-value" style={{top:`52%`}}>{speed}</span>
                <span className="park-enterance-dashboard__stat-value" style={{top:`56.5%`}}>{climbing}</span>
                <span className="park-enterance-dashboard__stat-value" style={{top:`61%`}}>{jumping}</span>
                <span className="park-enterance-dashboard__stat-value" style={{top:`65.5%`}}>{agality}</span>
                <span className="park-enterance-dashboard__stat-value" style={{top:`70.5%`}}>{flair}</span>
                <img className='park-enterance-dashboard__hero-body' src={heroBodies[heroName]}/>
                <span className='park-enterance-dashboard__coins-amount'>x {tokens}</span>
                <span className='park-enterance-dashboard__points-amount'>x {points}</span>
                <span className='park-enterance-dashboard__ranking-tekst'>
                    JESTEŚ
                    <span className='park-enterance-dashboard__ranking-place-text'>{placeInRanking}</span>
                    w RANKINGU
                    <div className='park-enterance-dashboard__ranking-flash' style={{display: showRankingFlash === true ? 'flex' : 'none'}}>
                        <img src={pointsFlash + "?x=" + Math.random()} />
                    </div>
                </span>
            </ScreenFrame>
        </>   
    );
};

export default ParkEntranceDashboard;
