import React from "react";
import Styles from "./styled";
import ScreenFrame from "../../templates/ScreenFrame";
import DoubleNav from "../../templates/DoubleNav";
import ImagesData from "../../../data/assets/images/images";
import useMaze from "./hook";

const { shadow, progressBarFlash, diamond } = ImagesData;

const {
    StampsWrapper,
    Stamp,
    Shadow,
    PointsAmount,
    PointsGain,
    StampContainer,
    DiamondIcon,
} = Styles;

const pointsLeftPosition = (idx: number, stampsCount: number) => {
    let left = idx < 6 ? idx * 17 : -95 + idx * 17;
    if (stampsCount === 12) {
        left = idx < 6 ? idx * 17 : -102 + idx * 17;
    }
    return left;
};

const Maze = () => {
    const {
        stamps,
        pointsdelta,
        animTrigger,
        pointFlashAnimActive,
        noOfKiosksCompleted,
        allPointsCollected,
        totalKiosksForAttraction,
    } = useMaze();

    return (
        <ScreenFrame>
            <DoubleNav />
            <StampsWrapper>
                {stamps.map((x, idx) => (
                    <StampContainer
                        key={idx}
                        animActive={allPointsCollected}
                        addCoinAnimActive={false}
                        leftDest={"0px"}
                        topDest={"0px"}
                        left={pointsLeftPosition(idx, stamps.length)}
                        top={idx >= 6 ? 55 : 5}
                    >
                        <Stamp src={x} />
                        {noOfKiosksCompleted == idx + 1 &&
                            totalKiosksForAttraction != idx + 1 && (
                                <>
                                    <PointsAmount
                                        left={"0"}
                                        top={"0"}
                                        isAnimationActive={true}
                                    >
                                        +{pointsdelta}
                                    </PointsAmount>
                                    <DiamondIcon
                                        left={"0"}
                                        top={"0"}
                                        isAnimationActive={true}
                                        src={diamond}
                                    />
                                    <PointsGain
                                        animActive={pointFlashAnimActive}
                                        src={
                                            progressBarFlash +
                                            "?x=" +
                                            animTrigger
                                        }
                                    />
                                </>
                            )}
                    </StampContainer>
                ))}
                <Shadow src={shadow} />
            </StampsWrapper>
        </ScreenFrame>
    );
};

export default Maze;
