import React, { useContext, useState } from "react";
import ImagesData from "../../../../data/assets/images/images";
import { AppContext } from "../../../App";
import BarWithBonuses from "./BarWithBonuses";
import CloudRow from "./CloudsRow";
import { calculateFlashLeft, calculatePointsLeft } from "./helpers";
// import { useProgressBarHook, useProgressBarSetup } from "./hook";
import { useProgressBarHook } from "./hook2";
import LevelsInfo from "./LevelsInfo";
import Styles from "./styles";

const { Container, PointsGainAnim, PointsGainAmount, CoinUnderFlash } = Styles;

const { pointsFlash, progressBarFlash } = ImagesData;

const ProgressBar = (props: any) => {
    const {
        appData: { deltaPoints },
    } = useContext(AppContext);

    const {
        bars,
        bonuses,
        anim,
        barAnimActive,
        clouds,
        percents,
        animActive,
        startAnim,
        bonusFlashActive,
        bonusFlashPosition,
        startFlashAnim,
    } = useProgressBarHook(
        props.progressBarPrizesData,
        props.setDisplayReward,
        props.setRewardType,
        props.setReward
    );

    // const { percents, playerProgressArr, barNumber, bars, bonuses, clouds } =
    //     useProgressBarSetup(props.progressBarPrizesData);

    // const {
    //     anim,
    //     barAnimActive,
    //     startAnim,
    //     startFlashAnim,
    //     animActive,
    //     bonusesUpdate,
    //     bonusFlashActive,
    //     bonusFlashPosition,
    // } = useProgressBarHook(
    //     percents,
    //     playerProgressArr,
    //     bonuses,
    //     props.setDisplayReward,
    //     props.setRewardType,
    //     props.setReward
    // );

    return (
        <Container>
            <LevelsInfo />
            <BarWithBonuses
                bars={bars}
                bonuses={bonuses}
                anim={anim}
                barAnimActive={barAnimActive}
            />
            <CloudRow clouds={clouds} />

            <PointsGainAnim
                left={calculateFlashLeft(percents)}
                active={animActive}
            >
                <img src={pointsFlash + "?x=" + startAnim} />
            </PointsGainAnim>

            <PointsGainAmount
                left={calculatePointsLeft(percents)}
                active={animActive}
            >
                +{deltaPoints}
            </PointsGainAmount>

            <CoinUnderFlash
                left={bonusFlashPosition - 26}
                active={bonusFlashActive}
                src={progressBarFlash + "?x=" + startFlashAnim}
            ></CoinUnderFlash>
        </Container>
    );
};

export default ProgressBar;
