import styled from "styled-components";
import { ConnectingAnim } from "../App/keyframes";

export const Background = styled.img`
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0px;
    left: 0px;
`;

const Styles = {
    RFIDInputComponent: styled.input`
        position: fixed;
        width: 0;
        height: 0;
        left: -10px;
        top: 0;
    `,
    ScreenWrapper: styled.div`
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 100vh;
        max-width: calc(100vh * 1.74);
        max-height: calc(100vw * 0.576);
    `,
    Content: styled.div`
        width: 100vw;
        height: 100vh;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    `,
};

export default Styles;
