import React, { useEffect, useState } from "react";
import { IMazeScreenFlash } from "./types";
import Styles from "./styles";
import ImagesData from "../../../data/assets/images/images";
import {
    mazeFlashSpots11,
    mazeFlashSpots12,
    mazeFlashElementsData,
} from "./data";

const {
    AnimationContainer,
    AnimationImage,
    AnimationPosition,
    AnimatedImage,
    AnimationImageOptimized,
    ContentContainer,
} = Styles;

const {
    mazeFlashLeftBottom,
    mazeFlashLeftTop,
    mazeFlashRightBottom,
    mazeFlashRightTop,
    optimizedMazeFlash,
} = ImagesData;

const MazeScreenFlash = ({
    active,
    currentMazeSpot,
    mazeSpotsCount,
}: IMazeScreenFlash) => {
    const [pos, setPos] = useState("left-top");
    // const [right, setRight] = useState("0%");
    // const [bottom, setBottom] = useState("0%");
    const [left, setLeft] = useState("0%");
    const [top, setTop] = useState("0%");

    useEffect(() => {
        if (currentMazeSpot > 0 && currentMazeSpot < 12) {
            const { pos, left, top } =
                mazeSpotsCount === 12
                    ? //@ts-ignore
                      mazeFlashSpots12[currentMazeSpot]
                    : //@ts-ignore
                      mazeFlashSpots11[currentMazeSpot];
            // const { pos, right, bottom } =
            //     mazeSpotsCount === 12
            //         ? //@ts-ignore
            //           mazeFlashSpotsIMG12[currentMazeSpot]
            //         : //@ts-ignore
            //           mazeFlashSpotsIMG11[currentMazeSpot];
            setPos(pos);
            setLeft(left);
            setTop(top);
        }
    }, [currentMazeSpot]);

    const currentSpotFlash = (pos: string) => {
        //@ts-ignore
        switch (pos) {
            case "left-top":
                return mazeFlashLeftTop;
            case "left-bottom":
                return mazeFlashLeftBottom;
            case "right-top":
                return mazeFlashRightTop;
            case "right-bottom":
                return mazeFlashRightBottom;
            default:
                // shouldn't ever get here
                return mazeFlashLeftTop;
        }
    };

    const currentSpotFlashImg = (pos: string) => {
        //@ts-ignore
        switch (pos) {
            case "left-top":
                return "scaleY(-1)";
            case "left-bottom":
                return "scaleX(1)";
            case "right-top":
                return "scaleX(-1) scaleY(-1)";
            case "right-bottom":
                return "scaleX(-1)";
            default:
                return "scaleX(1)";
        }
    };

    // const currentSpotFlashImg = (pos: string) => {
    //     //@ts-ignore
    //     switch (pos) {
    //         case "left-top":
    //             return "scaleX(-1)";
    //         case "left-bottom":
    //             return "scaleX(-1) scaleY(-1)";
    //         case "right-top":
    //             return "scaleX(1)";
    //         case "right-bottom":
    //             return "scaleY(-1)";
    //         default:
    //             return "scaleX(1)";
    //     }
    // };

    if (active) {
        return (
            // <ContentContainer>
            <AnimationContainer>
                {/* <AnimationPosition right={right} bottom={bottom}> */}
                {/* <AnimationImage
                    src={currentSpotFlash(pos)}
                    alt="loading..."
                    right={right}
                    bottom={bottom}
                /> */}
                <AnimationImageOptimized
                    src={optimizedMazeFlash}
                    transform={currentSpotFlashImg(pos)}
                    alt="loading..."
                    left={left}
                    top={top}
                />
                {/* </AnimationPosition> */}
                {/* {mazeFlashElementsData.map((el) => {
                    return (
                        <AnimatedImage
                            key={el.id}
                            src={el.image}
                            alt="Loading.."
                            delay={el.delay}
                            transform={currentSpotFlashImg(pos)}
                            right={right}
                            bottom={bottom}
                        />
                    );
                })} */}
            </AnimationContainer>
            // </ContentContainer>
        );
    } else {
        return <React.Fragment></React.Fragment>;
    }
};

export default MazeScreenFlash;
