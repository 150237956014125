import React, { memo } from "react";
import ImagesData from "../../../../../data/assets/images/images";
import BarWithBonuses from "./BarWithBonuses";
import LevelsInfo from "./LevelsInfo";
import useProgressBarSetupTest from "./hook";
import "./styles.css"

const { pointsFlash } = ImagesData;

const ProgressBar = memo(
    (props: any) => {
        const { progressBarsToFillAmount, singleProgressBarFillWidth, levelBonusses, currentPoints, amountToFillProgressBar, expOverflowScreen, showProgressFlash, progressFlashLeftPos} = useProgressBarSetupTest()
    
        return (
            <div className='progress-bar__container'>
                <LevelsInfo />
                <BarWithBonuses
                    currentPoints={currentPoints}
                    progressBarsToFillAmount={progressBarsToFillAmount}
                    singleProgressBarFillWidth={singleProgressBarFillWidth}
                    levelBonusses={levelBonusses}
                    amountToFillProgressBar={amountToFillProgressBar}
                    progressBarOverflow={expOverflowScreen}
                />
                <div className='progress-bar__progress-flash' style={{display: showProgressFlash === true ? 'flex' : 'none', left:`${progressFlashLeftPos}%`}} >
                    <img src={pointsFlash + "?x=" + Math.random()} />
                </div>
            </div>
        );
    }
)

export default ProgressBar;
