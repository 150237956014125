import React, { useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import api from "../../../api/api";
import useFetchData from "../../../api/apiHelpers";
import { AppContext } from "../../App";

const useGate = () => {
    const { pathname } = useLocation();
    const history = useHistory();
    const handler = useFetchData();

    // Safety mechanism
    const {
        appData: { testPlayerAtKiosk },
    } = useContext(AppContext);
    if (typeof testPlayerAtKiosk.data === "undefined") {
        history.push(pathname.replace("/gate", ""));
        window.location.reload();
    }

    const {
        appData: {
            loginData: {
                data: { token },
            },
            testPlayerAtKiosk: {
                data: {
                    data: { PlayerTokens, playtime_id, player_id, CardNumber },
                },
            },
        },
        dispatchAppData,
    } = useContext(AppContext);

    useEffect(() => {
        if (PlayerTokens === "0") {
            setTimeout(() => {
                dispatchAppData({ gifTrigger: true });
            }, 3800);
            setTimeout(() => {
                history.push(pathname.replace("/gate", ""));
            }, 4000);
        } else {
            setTimeout(() => {
                handler(async () => {
                    const response = await api.confirmAtKiosk(
                        token,
                        playtime_id,
                        1,
                        player_id,
                        CardNumber,
                        1
                    );
                });
            }, 3700);
            setTimeout(() => {
                dispatchAppData({ gifTrigger: true });
            }, 3800);
            setTimeout(() => {
                history.push(pathname.replace("/gate", ""));
            }, 4000);
        }
    }, []);

    return PlayerTokens;
};

export default useGate;
