import React, { useEffect, useContext, useState } from "react";
import { useLocation, useHistory } from "react-router";
import { AppContext } from "../../App";

const useBirthday = (currentPath: string) => {
    const {
        appData: { testPlayerAtKiosk },
        dispatchAppData,
    } = useContext(AppContext);
    const { pathname } = useLocation();
    const history = useHistory();
    const [missingGroupPoints, setMissingGroupPoints] = useState(0);
    const [allGroupPoints, setAllGroupPoints] = useState(0);

    if (typeof testPlayerAtKiosk.data === "undefined") {
        if (currentPath.includes("/birthday-points-missing")) {
            history.push(pathname.replace("/birthday-points-missing", ""));
        } else if (currentPath.includes("/birthday-get-tokens")) {
            history.push(pathname.replace("/birthday-get-tokens", ""));
        } else if (currentPath.includes("/birthday-congrats")) {
            history.push(pathname.replace("/birthday-congrats", ""));
        }
        window.location.reload();
    }

    useEffect(() => {
        if (testPlayerAtKiosk.data.data) {
            const pktperplayer = testPlayerAtKiosk.data.data.pointsperplayer;
            const totalPoints = testPlayerAtKiosk.data.data.totalpoints;
            const noofplayers = testPlayerAtKiosk.data.data.noofplayers;
            const missingPoints =
                parseInt(pktperplayer) * parseInt(noofplayers) -
                parseInt(totalPoints);
            setMissingGroupPoints(missingPoints);
            setAllGroupPoints(parseInt(totalPoints));
        }
    }, [testPlayerAtKiosk]);

    useEffect(() => {
        if (currentPath.includes("/birthday-points-missing")) {
            setTimeout(() => {
                dispatchAppData({ gifTrigger: true });
            }, 3800);
            setTimeout(() => {
                history.push(pathname.replace("/birthday-points-missing", ""));
            }, 4000);
        } else if (currentPath.includes("/birthday-get-tokens")) {
            setTimeout(() => {
                dispatchAppData({ gifTrigger: true });
            }, 3800);
            setTimeout(() => {
                history.push(pathname.replace("/birthday-get-tokens", ""));
            }, 4000);
        } else if (currentPath.includes("/birthday-congrats")) {
            setTimeout(() => {
                dispatchAppData({ gifTrigger: true });
            }, 3800);
            setTimeout(() => {
                history.push(pathname.replace("/birthday-congrats", ""));
            }, 4000);
        }
    }, []);

    return { missingGroupPoints, allGroupPoints };
};

export default useBirthday;
