import Styles from "./styled";
import ImagesData from "../../../data/assets/images/images";

const { Background, StarsLeft, StarsRight } = Styles;
const { stars1, stars2, gradientRectangle } = ImagesData;

const ScreenBackground = () => {
    return (
        <>
            <Background src={gradientRectangle} />
            <StarsLeft src={stars1} />
            <StarsRight src={stars2} />
        </>
    );
};

export default ScreenBackground;
