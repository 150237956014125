import React, { useContext, useEffect, useState } from "react";
import { BarContext } from "../..";
import ImagesData from "../../../../../data/assets/images/images";
import { GetLevelName, GetNextLevelName } from "../../../../../data/levelNames";
import { AppContext } from "../../../../App";
import Styles from "./styles";

const {
    Col,
    LevelInfoContainer,
    LeftLevel,
    LevelName,
    LevelNumber,
    LevelIcon,
    NextLevelFlashIcon,
} = Styles;

const { arrowRight, GetLevelIcon, GetNextLevelIcon, progressBarFlash } =
    ImagesData;

const LevelsInfo = () => {
    const {
        appData: { levels, finishPoints, playerLevel },
    } = useContext(AppContext);
    const {
        barData: { nextLevelIconFlashTrigger, nextLevelTrigger },
    } = useContext(BarContext);

    const [level, setLevel] = useState(playerLevel);

    useEffect(() => {
        if (nextLevelTrigger) {
            setLevel(playerLevel);
        } else {
            let tempLevel = 0;
            if (levels.data.data) {
                for (const level of levels.data.data) {
                    if (finishPoints < parseInt(level.pointsrequired)) {
                        break;
                    }
                    tempLevel = parseInt(level.id);
                }
            }
            const currentPlayerLevel =
                tempLevel > playerLevel ? playerLevel + 1 : playerLevel;
            setLevel(currentPlayerLevel);
        }
    }, [finishPoints, nextLevelTrigger]);

    const levelIcon = GetLevelIcon(level, true);
    const currentLevelName = GetLevelName(level);
    const nextLevelIcon = GetNextLevelIcon(level, true);
    const nextLevelName = GetNextLevelName(level);

    return (
        <LevelInfoContainer>
            <LeftLevel>
                <LevelIcon>
                    <img src={levelIcon} alt="left lvl icon" />
                </LevelIcon>
                <Col>
                    <LevelNumber>Poziom {level}</LevelNumber>
                    {currentLevelName.includes(" ") ? (
                        <>
                            <LevelName type="small">
                                {currentLevelName.split(" ")[0]}
                            </LevelName>
                            <LevelName type="small">
                                {currentLevelName.split(" ")[1]}
                            </LevelName>
                        </>
                    ) : (
                        <LevelName type="big">{currentLevelName}</LevelName>
                    )}
                </Col>
            </LeftLevel>
            <LeftLevel>
                {playerLevel < levels.data.data.length && (
                    <React.Fragment>
                        <Col>
                            <LevelNumber>Poziom {level + 1}</LevelNumber>
                            {nextLevelName.includes(" ") ? (
                                <>
                                    <LevelName type="small">
                                        {nextLevelName.split(" ")[0]}
                                    </LevelName>
                                    <LevelName type="small">
                                        {nextLevelName.split(" ")[1]}
                                    </LevelName>
                                </>
                            ) : (
                                <LevelName type="big">
                                    {nextLevelName}
                                </LevelName>
                            )}
                            {/* <LevelNumber>Poziom {level + 1}</LevelNumber>
                                <LevelName type="big">{nextLevelName}</LevelName> */}
                        </Col>
                        <LevelIcon>
                            <img src={nextLevelIcon} alt="left lvl icon" />
                            {/* <NextLevelFlashIcon
                                    active={nextLevelIconFlashTrigger}
                                    src={
                                        progressBarFlash + "?x=" + nextLevelIconFlashTrigger
                                    }
                                    alt="next level flash"
                                /> */}
                        </LevelIcon>
                        <LevelIcon>
                            <img src={arrowRight} alt="left lvl icon" />
                        </LevelIcon>
                    </React.Fragment>
                )}
            </LeftLevel>
        </LevelInfoContainer>
    );
};

export default LevelsInfo;
