import React from "react";
import ScreenBackground from "../../templates/ScreenBackground";
import Styles from "./styles";
import ImagesData from "../../../data/assets/images/images";
import { useRanking } from "./hook";

const {
    ScreenWrapper,
    Content,
    Shadow,
    ScreenNameWrapper,
    ImageWithSize,
    TitleWrapper,
    RankingContainer,
    RankingBar,
    PointsText,
    Diamond,
    CoinIcon,
} = Styles;

const { shadow, logoCMK, rankingDailyText, diamond, coin } = ImagesData;

const genDummyData = () => {
    const data = [];
    for (let i = 0; i < 20; i++) {
        data.push({
            // player: ("VeryLongPlayerNameNr " + (i + 1)).slice(0, 16),
            nickname: ("PlayerNameNr " + (i + 1)).slice(0, 16),
            noOfStars: 20 - i,
            noOfPoints: 20 - i,
        });
    }
    return data;
};

const positionColor = (pos: number) => {
    if (pos === 0) return "#ffcc00";
    else if (pos % 2 === 0) return "#CBC3E3";
    else return "white";
};

const RankingScreen = () => {
    // DATA FROM API
    const topPlayers = useRanking();
    return (
        <Content>
            <ScreenWrapper>
                <ScreenBackground />
                <Shadow src={shadow} />
                <TitleWrapper>
                    <ImageWithSize src={rankingDailyText} size={70} />
                </TitleWrapper>

                <RankingContainer>
                    {topPlayers.map((player, idx) => {
                        return (
                            <RankingBar
                                key={idx}
                                bgColor={positionColor(idx)}
                                // topPos={idx * 3.68 + 13.5}
                                // topPos={idx * 3.7}
                            >
                                <PointsText fontSize={1.7} grow={2}>
                                    {idx + 1}
                                </PointsText>
                                <PointsText fontSize={1.9} grow={11}>
                                    {player.nickname.slice(0, 16)}
                                </PointsText>
                                <Diamond src={diamond} />
                                <PointsText fontSize={1.7} grow={65}>
                                    {player.noOfPoints}
                                </PointsText>
                                <CoinIcon src={coin} />
                                <PointsText fontSize={1.7} grow={88}>
                                    {player.noOfStars}
                                </PointsText>
                            </RankingBar>
                        );
                    })}
                </RankingContainer>

                <ScreenNameWrapper>
                    <ImageWithSize src={logoCMK} size={30} />
                </ScreenNameWrapper>
            </ScreenWrapper>
        </Content>
    );
};

export default RankingScreen;
