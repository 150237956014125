import { PropsWithChildren, createContext, useEffect } from "react";
import Styles from "./styled";
import IGKKProps, { IGKKContext } from "./types";
import useGKKData from "./hook";
import scanBand from "../../data/assets/images/PRZYLOZ_OPASKE.png";
import cave from "../../data/assets/images/JASKINIA_LOGO.png";

const { ScreenWrapper, Content } = Styles;

const GKKContextValue = {
    topTitle: scanBand,
    screenName: cave,
};

export const GKKContext = createContext<IGKKContext>(GKKContextValue);

const GKK = (props: PropsWithChildren<IGKKProps>) => {
    const { id } = props;
    const { StartScreen } = props.data;
    useGKKData(id);

    return (
        <>
            <GKKContext.Provider value={GKKContextValue}>
                <Content>
                    <ScreenWrapper>
                        {StartScreen && <StartScreen />}
                    </ScreenWrapper>
                </Content>
            </GKKContext.Provider>
        </>
    );
};

export default GKK;
