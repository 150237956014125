const componentsData:{id:string}[] = [
  {
    id: "GKK01",
  },
  {
    id: "GKK02",
  },
  {
    id: "GKK03",
  },
  {
    id: "GKK04",
  },
  {
    id: "GKK05",
  },
  {
    id: "GKK06",
  },
  {
    id: "GKK07",
  },
  {
    id: "GKK08",
  },
  {
    id: "GKK09",
  },
  {
    id: "GKK10",
  },
  {
    id: "GKK11",
  },
  {
    id: "GKK12",
  },
  {
    id: "GKK13",
  },
  {
    id: "GKK14",
  },
  {
    id: "GKK15",
  },
  {
    id: "GKK16",
  },
  {
    id: "GKK17",
  },
  {
    id: "GKK18",
  },
  {
    id: "GKK19",
  },
  {
    id: "GKK20",
  },
  {
    id: "GKK21",
  },
  {
    id: "GKK22",
  },
  {
    id: "GKK23",
  },
  {
    id: "GKK24",
  },
  {
    id: "GKK25",
  },
  {
    id: "GKK26",
  },
  {
    id: "GKK27",
  },
  {
    id: "GKK28",
  },
  {
    id: "GKK29",
  },
  {
    id: "GKK30",
  },
];

export default componentsData;
