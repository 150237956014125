import styled, { keyframes } from "styled-components";
import { IPointsAnimPos, IPointsAnimActive } from "./types";

const pointsGainAnim = keyframes`
    0%{
        transform: scale3d(0.6,0.6,0.6);
        opacity: 0;  
    }
    50%{
        opacity: 1;  
    }
    100%{
        transform: scale3d(1,1,1);
        opacity: 0;  
    }
`;

export const Styles = {
    Container: styled.div`
        display: flex;
        flex-flow: column;
        justify-content: center;
        top: -12%;
        /* z-index: 60; */
        width: 100vw;
        height: 100vh;
        overflow: hidden;
    `,
    PointsGainAnim: styled.div<IPointsAnimActive>`
        position: absolute;
        left: ${(props) => props.left}%;
        display: ${(props) => (props.active ? "flex" : "none")};
        z-index: 65;
        top: -10%;
        width: 80%;
        /* border: 2px solid black; */
        & > img {
            width: 100%;
        }
    `,
    PointsGainAmount: styled.div<IPointsAnimActive>`
        position: absolute;
        animation: ${pointsGainAnim} 1.3s ease-out;
        /* animation-fill-mode: forwards; */
        left: ${(props) => props.left}%;
        top: 47%;
        display: ${(props) => (props.active ? "flex" : "none")};
        color: white;
        font-size: 10vw;
        z-index: 90;
        text-shadow: 0.7vw 0.7vw 0px rgba(42, 42, 42, 1);
        font-weight: 700;
    `,
    CoinUnderFlash: styled.img<IPointsAnimActive>`
        position: absolute;
        left: ${(props) => props.left}%;
        top: 5%;
        display: ${(props) => (props.active ? "flex" : "none")};
        /* border: 2px solid black; */
        z-index: 80;
        width: 60%;
    `,
};

export default Styles;
