import React, {useEffect, useState} from "react";
import { useContext } from "react";
import { AppContext } from "../../../../App";
import { IUseProgressBarSetup } from "./types";

export default function useProgressBarSetupTest():IUseProgressBarSetup {

    const [exp, setExp] = useState<number>(0)
    const [overflow, setOverflow] = useState<boolean>(false)
    const [showProgressFlash, setShowProgressFlash] = useState<boolean>(false)

    const {
        dispatchAppData,
        appData:{startPoints, playerDeltaPoints, levels:{data:{data:levels}},premiums:{data:{data:premiums}},tokens:{data:{data:tokens}}},
        appData
    } = useContext(AppContext);

    let currentPoints = startPoints + playerDeltaPoints;
    //let currentPoints = 150

    if(currentPoints > levels[levels.length - 1].pointsrequired){
        currentPoints = levels[levels.length - 1].pointsrequired - 10
    }

    const startLevelPoints = levels[Math.max(levels.findIndex((el:{pointsrequired:string}) => parseInt(el.pointsrequired) > currentPoints) - 1,0)].pointsrequired
    const endLevelPoints = levels[Math.max(levels.findIndex((el:{pointsrequired:string}) => parseInt(el.pointsrequired) > currentPoints), 0)].pointsrequired

    const playerLevel = levels.findIndex((el:{pointsrequired:string}) => parseInt(el.pointsrequired) > currentPoints)
    
    //Attention -> name of variable: 'piontsRequired' is not a mistake. This is name field that is comming from backend.
    const tempPremiumsAvailable = premiums.filter((el:{piontsRequired:string}) => (parseInt(el.piontsRequired) > startLevelPoints && parseInt(el.piontsRequired) < endLevelPoints))
    const tempTokensAvailable = tokens.filter((el:{pointsrequired:string}) => (parseInt(el.pointsrequired) > startLevelPoints && parseInt(el.pointsrequired) < endLevelPoints))

    const premiumsAvailable = tempPremiumsAvailable.map((el:{piontsRequired:string}) => ({...el, pointsrequired: el.piontsRequired, type:'chest'}))
    const tokensAvailable = tempTokensAvailable.map((el:any) => ({...el, type: 'coin'}))

    const levelBonusses:{type:'coin' | 'chest', pointsrequired:string}[] = tokensAvailable.concat(premiumsAvailable).sort((prev:{pointsrequired:string}, next:{pointsrequired:string}) => parseInt(prev.pointsrequired) > parseInt(next.pointsrequired) ? 1 : -1)

    const levelBonusesAmount = levelBonusses.length

    const expBarAmount = levelBonusesAmount + 1

    const progressBarsToFillAmount = levelBonusesAmount + 1
    const singleProgressBarFillWidth = (100 / progressBarsToFillAmount) - 0.7

    const singleBarPrecentageFullFill = 100 / expBarAmount

    let amountOfBarsUnlocked = levelBonusses.findIndex((el:{pointsrequired:string}) => parseInt(el.pointsrequired) > currentPoints)

    amountOfBarsUnlocked = amountOfBarsUnlocked === -1 ? 0 : amountOfBarsUnlocked

    let expToCalculateLastBarLenght:number = 0

    let precentageFillOfLastBar = 0;
    if(levelBonusses.length > 0) {
        expToCalculateLastBarLenght = currentPoints - (amountOfBarsUnlocked === 0 ? parseInt(levels[playerLevel - 1].pointsrequired) : parseInt(levelBonusses[amountOfBarsUnlocked - 1].pointsrequired))
        precentageFillOfLastBar = ((expToCalculateLastBarLenght / (parseInt(levelBonusses[amountOfBarsUnlocked ].pointsrequired) - (amountOfBarsUnlocked === 0 ? parseInt(levels[playerLevel - 1].pointsrequired) : parseInt(levelBonusses[amountOfBarsUnlocked - 1]?.pointsrequired))) * singleBarPrecentageFullFill))
    } else {
        expToCalculateLastBarLenght = currentPoints - startLevelPoints
        precentageFillOfLastBar = (expToCalculateLastBarLenght / (endLevelPoints - startLevelPoints)) * 100
    }

    const amountToFillProgressBar = (amountOfBarsUnlocked * singleBarPrecentageFullFill) + precentageFillOfLastBar
    const expOverflowScreen:boolean = amountToFillProgressBar > 57 ? true : false

    const progressFlashLeftPos:number = (amountToFillProgressBar * 1.6) - 15

    useEffect(() => {
        setTimeout(() => {
            setExp(amountToFillProgressBar)
        }, 1500)
        setTimeout(() => {
            setOverflow(expOverflowScreen)
        }, 3000)
        setTimeout(() => {
            setShowProgressFlash(true)
        }, 2400)
        setTimeout(() => {
            setShowProgressFlash(false)
        }, 2900)
    }, [])

    return {currentPoints,progressBarsToFillAmount,singleProgressBarFillWidth,levelBonusses,amountToFillProgressBar:exp,expOverflowScreen:overflow, showProgressFlash,progressFlashLeftPos}
}