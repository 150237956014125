import { useState, useContext } from "react";
import { AppContext } from "../../../../App";
import Styles from "./styled";
import ImagesData from "../../../../../data/assets/images/images";
import { pointsActiveLookupTable } from "./data";

const { AnimationWrapper, Band, Diamond, PointsText } = Styles;
const { diamond, handIcon } = ImagesData;

const Hand = () => {
    const {
        appData: {
            infoData: {
                data: {
                    data: [element],
                },
            },
        },
    } = useContext(AppContext);
    const { attraction_id } = element;
    const [pointsVisible] = useState(
        // @ts-ignore
        pointsActiveLookupTable[attraction_id]
    );
    const {
        appData: { deltaPoints },
    } = useContext(AppContext);
    return (
        <>
            {pointsVisible && deltaPoints > 0 && (
                <>
                    <Diamond src={diamond} />
                    <PointsText>+{deltaPoints}</PointsText>
                </>
            )}
            <AnimationWrapper>
                <Band src={handIcon} />
            </AnimationWrapper>
        </>
    );
};

export default Hand;
