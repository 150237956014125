import { useContext, useEffect } from "react";

import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import { AppContext } from "../../../App";
import api from "../../../../api/api";
import useFetchData from "../../../../api/apiHelpers";

const useTokenPayer = () => {
    const { pathname } = useLocation();
    const history = useHistory();
    const {
        appData: {
            loginData: {
                data: { token },
            },
            testPlayerAtKiosk: {
                data: {
                    data: { playtime_id, player_id, CardNumber, PlayerTokens },
                },
            },
            infoData: {
                data: {
                    data: [{ name }],
                },
            },
        },
        dispatchAppData,
    } = useContext(AppContext);

    const handler = useFetchData();

    useEffect(() => {
        let tokenPayed = true;
        setTimeout(async () => {
            try {
                await axios.get("http://localhost:4005/dispense/1");
            } catch {
                tokenPayed = false;
            }
        }, 2000);
        setTimeout(() => {
            dispatchAppData({ gifTrigger: true });
        }, 2800);
        setTimeout(() => {
            if (tokenPayed) {
                handler(async () => {
                    const response = await api.confirmAtKiosk(
                        token,
                        playtime_id,
                        1,
                        player_id,
                        CardNumber,
                        0
                    );
                });
                handler(async () => {
                    const response = await api.hopperLog(
                        token,
                        name,
                        playtime_id,
                        PlayerTokens,
                        "1",
                        "0"
                    );
                });
                history.push(pathname.replace("/token-payer", ""));
            } else {
                history.push(pathname.replace("/token-payer", "/kiosk-error"));
            }
        }, 2900);
    }, []);
};

export default useTokenPayer;
