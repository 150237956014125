import {useContext,useEffect,useState} from "react";
import { AppContext } from "../../../App";
import { useHistory, useLocation } from "react-router";
import { countingGaps } from "../data";
import api from "../../../../api/api";
import useFetchData from "../../../../api/apiHelpers";
import { HeroNamesType } from "./types";
import { PlayerLocalData } from "../types";

const HERO_ID = "-";
const TOP_PLAYERS = 1000;

//const SPEED = 130
//const CLIMBING = 10
//const JUMPING = 70
//const AGALITY = 130
//const FLAIR = 108

export default function useParkEnteranceDashboard() {

    const [placeInRanking, setPlaceInRanking] = useState<number>(999)

    const history = useHistory();
    const location = useLocation();
    const handler = useFetchData();

    // Safety mechanism
    const {
        appData: {playerStats,loginData, progressBarPrizesData,testPlayerAtKiosk:{data: {data: apiData}} },
    } = useContext(AppContext);
    if (typeof apiData === "undefined") {
        history.push(location.pathname.replace("/progress-bar-test", ""));
        window.location.reload();
    }

    const [heroName, setHeroName] = useState<HeroNamesType>('expresso' as HeroNamesType)

    const [showRankingFlash, setShowRankingFlash] = useState<boolean>(false)

    const [tokens, setTokens] = useState<number>(0)
    const [points, setPoints] = useState<number>(0)

    const [speed, setSpeed] = useState<number>(0)
    const [climbing, setClimbing] = useState<number>(0)
    const [jumping, setJumping] = useState<number>(0)
    const [agality, setAgality] = useState<number>(0)
    const [flair, setFlair] = useState<number>(0)

    const tokenCounterGap = apiData.PlayerTokens / countingGaps.length
    const pointsCounterGap = apiData.PlayerPoints / countingGaps.length
    const speedCounterGap = playerStats?.speed / countingGaps.length
    const climbingCounterGap = playerStats.climbing / countingGaps.length
    const jumpingCounterGap = playerStats.jumping / countingGaps.length
    const agalityCounterGap = playerStats.agality / countingGaps.length
    const flairCounterGap = playerStats.flair / countingGaps.length

    useEffect(() => {
        //@ts-ignore
        if (progressBarPrizesData === []) {
            setTimeout(() => {
                const path = location.pathname.slice(0, 6);
                history.push(path);
            }, 2000);
        }

        let newPlayersState:PlayerLocalData = JSON.parse(localStorage.getItem("players") as string) as PlayerLocalData
        setHeroName(newPlayersState.filter(obj => obj.id === apiData.CardNumber)[0].hero as HeroNamesType)

        handler(async () => {
            const response = await api.bestResults(
                loginData.data.token,
                HERO_ID,
                TOP_PLAYERS
            );
            if (response.data.data) {
                setTimeout(() => {
                    let increasedPlaceInRanking = response.data.data.findIndex((el:{nickname:string}) => el.nickname === apiData.PlayerNickName) + countingGaps.length + 1

                setPlaceInRanking(increasedPlaceInRanking)

                const tokensIncrementingValues:number[] = []
                const coinsIncrementValues:number[] = []
                const speedIncrementValues:number[] = []
                const climbingIncrementValues:number[] = []
                const jumpingIncrementValues:number[] = []
                const agalityIncrementValues:number[] = []
                const flairIncrementValues:number[] = []

                for(let i = 0; i < countingGaps.length; i++){
                    tokensIncrementingValues.push(i * tokenCounterGap)
                    coinsIncrementValues.push(i * pointsCounterGap)
                    speedIncrementValues.push(i * speedCounterGap)
                    climbingIncrementValues.push(i * climbingCounterGap)
                    jumpingIncrementValues.push(i * jumpingCounterGap)
                    agalityIncrementValues.push(i * agalityCounterGap)
                    flairIncrementValues.push(i * flairCounterGap)
                }

                for(let i = 0; i < countingGaps.length; i++){

                    setTimeout(() => {
                        setTokens(Math.round(tokensIncrementingValues[i]))
                        setPoints(Math.round(coinsIncrementValues[i]))
                        setSpeed(Math.round(speedIncrementValues[i]))
                        setClimbing(Math.round(climbingIncrementValues[i]))
                        setJumping(Math.round(jumpingIncrementValues[i]))
                        setAgality(Math.round(agalityIncrementValues[i]))
                        setFlair(Math.round(flairIncrementValues[i]))
                    }, countingGaps[i])

                    setTimeout(() => {
                        setPlaceInRanking(state => state - 1)
                    }, countingGaps[i] * 3)

                    increasedPlaceInRanking--;
                }
                    setTimeout(() => {
                        setTokens(apiData.PlayerTokens)
                        setPoints(apiData.PlayerPoints)
                        setSpeed(playerStats?.speed)
                        setClimbing(playerStats?.climbing)
                        setJumping(playerStats?.jumping)
                        setAgality(playerStats?.agality)
                        setFlair(playerStats?.flair)
                    }, countingGaps[countingGaps.length - 1] + 200)
                }, 1000)

                setTimeout(() => {
                    setShowRankingFlash(true)
                }, 5800)
                setTimeout(() => {
                    setShowRankingFlash(false)
                }, 6300)
            }
        });
      
    }, [progressBarPrizesData]);



    return {heroName,showRankingFlash,tokens,points,speed,climbing,jumping,agality,flair,placeInRanking, apiData}
}