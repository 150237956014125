import Screen1 from "../../Screens/_DOMAIN/Screen1";
import Screen04 from "../../Screens/_DOMAIN/Screen04";
import Screen05 from "../../Screens/_DOMAIN/Screen05";
import Screen06 from "../../Screens/_DOMAIN/Screen06";
import Screen08 from "../../Screens/_DOMAIN/Screen08";
import Screen09 from "../../Screens/_DOMAIN/Screen09";
import Screen10 from "../../Screens/_DOMAIN/Screen10";
import Screen11 from "../../Screens/_DOMAIN/Screen11";
import Screen12 from "../../Screens/_DOMAIN/Screen12";
import Screen13 from "../../Screens/_DOMAIN/Screen13";
import Screen14 from "../../Screens/_DOMAIN/Screen14";
import Screen16 from "../../Screens/_DOMAIN/Screen16";
import Screen18 from "../../Screens/_DOMAIN/Screen18";
import Screen19 from "../../Screens/_DOMAIN/Screen19";
import Screen20 from "../../Screens/_DOMAIN/Screen20";
import Screen21 from "../../Screens/_DOMAIN/Screen21";
import Screen25 from "../../Screens/_DOMAIN/Screen25";
import Screen26 from "../../Screens/_DOMAIN/Screen26";
import Screen27 from "../../Screens/_DOMAIN/Screen27";
import Screen28 from "../../Screens/_DOMAIN/Screen28";
import Screen29 from "../../Screens/_DOMAIN/Screen29";
import Screen30 from "../../Screens/_DOMAIN/Screen30";
import Screen32 from "../../Screens/_DOMAIN/Screen32";
import Screen33 from "../../Screens/_DOMAIN/Screen33";
import Screen36 from "../../Screens/_DOMAIN/Screen36";
import Screen41 from "../../Screens/_DOMAIN/Screen41";
import Screen42 from "../../Screens/_DOMAIN/Screen42";
import Screen43 from "../../Screens/_DOMAIN/Screen43";
import Screen44 from "../../Screens/_DOMAIN/Screen44";

// Only for a start screen
const screensData = {
    "1": Screen1,
    "4": Screen04,
    "5": Screen05,
    "6": Screen06,
    "8": Screen08,
    "9": Screen09,
    "10": Screen10,
    "11": Screen11,
    "12": Screen12,
    "13": Screen13,
    "14": Screen14,
    "16": Screen16,
    "18": Screen18,
    "19": Screen19,
    "20": Screen20,
    "21": Screen21,
    "25": Screen25,
    "26": Screen26,
    "27": Screen27,
    "28": Screen28,
    "29": Screen29,
    "30": Screen30,
    "32": Screen32,
    "33": Screen33,
    "36": Screen36,
    "41": Screen41,
    "42": Screen42,
    "43": Screen43,
    "44": Screen44
};
// Success and failure screens
const screensRoutesData = {
    "4": "/come-back-counter",
    "5": "/token-box",
    "6": "/scan-band",
    "8": "/progress-bar-test",
    "9": "/scan-band",
    "10": "/EMPTY", ////////////////////////
    "11": "/scan-band",
    "12": "/EMPTY", ////////////////////////
    "13": "/token-box",
    "14": "/welcome",
    "16": "/load-hero",
    "18": "/maze",
    "19": "/maze",
    "20": "/animator-at-kiosk",
    "21": "/animator-away",
    "22": "/birthday-congrats",
    "23": "/birthday-points-missing",
    "25": "/maze",
    "26": "/gate",
    "27": "/EMPTY", ////////////////////////
    "28": "/load-hero", 
    "29": "/come-back-counter",
    "30": "/gate",
    "32": "/total-playtime",
    "33": "/birthday-get-tokens",
    "36": "/load-hero",
    "41": "/token-box",
    "42": "/scan-band",
    "43": "/token-box",
    "45": "/load-hero"
};

export { screensData, screensRoutesData };
