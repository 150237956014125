import ScreenBackground from "../../templates/ScreenBackground";
import DoubleNav from "../../templates/DoubleNav";
import ProgressBar from "./ProgressBar";
import ScreenFrame from "../../templates/ScreenFrame";
import RewardScreen from "./RewardScreen";
import {
    createContext,
    useContext,
    useEffect,
    useReducer,
    useState,
} from "react";
import { AppContext } from "../../App";
import barContext, { IBarContext } from "./context";
import NextLevelAnimation from "./NextLevelAnimation";
import NextLevelScreen from "./NextLevelScreen";
import { useHistory, useLocation } from "react-router";

export const BarContext = createContext<IBarContext>(barContext);

const PlayerProgressBarTest = () => {
    const [displayReward, setDisplayReward] = useState(false);
    const [rewardType, setRewardType] = useState("token");
    const [reward, setReward] = useState({});

    const history = useHistory();
    const location = useLocation();

    // Safety mechanism
    const {
        appData: { testPlayerAtKiosk },
    } = useContext(AppContext);
    if (typeof testPlayerAtKiosk.data === "undefined") {
        history.push(location.pathname.replace("/progress-bar-test", ""));
        window.location.reload();
    }

    const {
        appData: { progressBarPrizesData },
    } = useContext(AppContext);

    const [barData, dispatchBarData] = useReducer(
        (state: object, payload: any) => ({ ...state, ...payload }),
        barContext.barData
    );

    useEffect(() => {
        //@ts-ignore
        if (progressBarPrizesData === []) {
            setTimeout(() => {
                const path = location.pathname.slice(0, 6);
                history.push(path);
            }, 2000);
        }
    }, [progressBarPrizesData]);

    return (
        <BarContext.Provider value={{ barData, dispatchBarData }}>
            <ScreenFrame>
                <ScreenBackground />
                {displayReward && (
                    <RewardScreen
                        displayReward={displayReward}
                        rewardType={rewardType}
                        reward={reward}
                    />
                )}
                {barData.nextLevelScreen && <NextLevelScreen />}
                {barData.nextLevelAnimation && <NextLevelAnimation />}
                <DoubleNav />
                <ProgressBar
                    progressBarPrizesData={progressBarPrizesData}
                    setDisplayReward={setDisplayReward}
                    setRewardType={setRewardType}
                    setReward={setReward}
                />
            </ScreenFrame>
        </BarContext.Provider>
    );
};

export default PlayerProgressBarTest;
